import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../../../../../commons/components/runtime-context/connect'
import {isChooseSeatMode} from '../../../../selectors/navigation'
import {Ticket as Presentation} from './ticket'
import {TicketOwnProps, TicketRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): TicketRuntimeProps => ({
  chooseSeatMode: isChooseSeatMode(state),
})

export const Ticket = connect<TicketOwnProps, TicketRuntimeProps>(mapRuntime)(Presentation)

export * from './interfaces'
