import classNames from 'classnames'
import React from 'react'
import cn from '../../../classnames.scss'
import s from './title.scss'
import {TitleProps} from './index'

export const Title: React.FC<TitleProps> = ({text, dataHook = '', className}) => (
  <h2 className={classNames(s.title, cn.evTitleFont, cn.formTitleColor, className)} data-hook={dataHook}>
    {text}
  </h2>
)
