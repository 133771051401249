import {isFeeAdded, saleScheduled, saleStarted} from '@wix/wix-events-commons-statics'
import {TicketWithFormattedSalePeriod} from '@wix/events-types'
import {calculateTicketsTotalPrice} from '../services/payment'
import {SelectedTickets, State} from '../types'

export const getTickets = (state: State) => state.tickets

export const getTicketById = (tickets: TicketWithFormattedSalePeriod[], id: string) =>
  tickets.find(ticket => ticket.id === id)

export const getTicketOrderIndexById = (tickets: wix.events.ticketing.TicketDefinition[], id: string) =>
  tickets.findIndex(ticket => ticket.id === id)

export const areAllTicketsSoldOut = (tickets: wix.events.ticketing.TicketDefinition[]) =>
  !tickets.find((ticket: wix.events.ticketing.TicketDefinition) => !!ticket.limitPerCheckout)

export const isPaidTicketSelected = (
  tickets: wix.events.ticketing.TicketDefinition[],
  selectedTickets: SelectedTickets,
) => calculateTicketsTotalPrice(tickets, selectedTickets) > 0

export const isWixFeeTypeIsAdded = (ticket: wix.events.ticketing.TicketDefinition) =>
  isFeeAdded(ticket.wixFeeConfig?.type)

export const hasScheduledTickets = (tickets: wix.events.ticketing.TicketDefinition[]) =>
  Boolean(tickets.find(ticket => saleScheduled(ticket)))

export const hasTicketsOnSale = (tickets: wix.events.ticketing.TicketDefinition[]) =>
  Boolean(tickets.find(ticket => saleStarted(ticket)))
