import {getInputControlMeta} from '@wix/wix-events-commons-statics'
import React from 'react'
import {Label} from '../../commons/label'
import {Radio as RadioItem} from '../../commons/radio'
import fs from '../../fields.scss'
import s from './radio.scss'
import {RadioProps} from './index'

export class Radio extends React.Component<RadioProps> {
  componentDidMount() {
    const {
      input: {value, onChange},
      control,
    } = this.props
    const {options} = getInputControlMeta(control)

    onChange(value || options[0])
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const {
      input: {onChange, value},
      control,
    } = this.props
    const {options} = getInputControlMeta(control)
    const selectedOptionIndex = options.findIndex(option => option === value) || 0

    switch (event.keyCode) {
      case 37: // arrow left
      case 38: // arrow up
        event.preventDefault()
        if (selectedOptionIndex > 0) {
          onChange(options[selectedOptionIndex - 1])
        } else {
          onChange(options[options.length - 1])
        }
        return

      case 39: // arrow right
      case 40: // arrow down
        event.preventDefault()
        if (selectedOptionIndex < options.length - 1) {
          onChange(options[selectedOptionIndex + 1])
        } else {
          onChange(options[0])
        }
        return

      default:
        return
    }
  }

  renderOption = (option: string, idx: number) => {
    const {
      input: {onChange, value},
      control,
    } = this.props
    const {name} = getInputControlMeta(control)

    return (
      <div className={s.option} key={idx}>
        <RadioItem name={name} value={option} checked={value === option} onChange={onChange} />
      </div>
    )
  }

  render() {
    const {label, options} = getInputControlMeta(this.props.control)

    return (
      <div className={fs.field} tabIndex={0} aria-label={label} onKeyDown={this.handleKeyDown}>
        <Label classes={fs.customCaption} dataHook="radio-caption" required={false} caption={label} />
        <div>{options.map(this.renderOption)}</div>
      </div>
    )
  }
}
