import {
  getEventTitle,
  getPreliminaryInvoice,
  getTaxConfig,
  hasSeatingPlan,
  isAssignedTickets,
  isDateTbd,
} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {OrderStatus} from '@wix/events-types'
import {ReservationState} from '../../../../../../commons/enums'
import {isTemplate} from '../../../../../../commons/selectors/instance'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {showGroupCard} from '../../../selectors/groups'
import {getSelectedTickets} from '../../../selectors/selected-tickets'
import {OrderSuccessRuntimeProps} from './interfaces'
import {OrderSuccess as Presentation} from './order-success'

const mapRuntime = ({
  state,
  actions: {ticketedThankYouPageLoaded, navigateToMainPage},
}: DetailsPageAppProps): OrderSuccessRuntimeProps => {
  const {event, checkout} = state
  const template = isTemplate(state)
  const order = template
    ? {
        firstName: checkout.buyerDetails.firstName,
        email: checkout.buyerDetails.email,
        formattedCreatedDate: state.dates.common.nowYearMonthDayFormatted,
        invoice: getPreliminaryInvoice(state.tickets, getSelectedTickets(state), getTaxConfig(state.event)),
        status: OrderStatus.FREE,
      }
    : state.placedOrder.order

  return {
    order,
    formattedOrderCreatedDate: state.dates.common.formattedOrderCreatedDate,
    eventTitle: getEventTitle(event),
    dateTbd: isDateTbd(event),
    isAssignedTickets: isAssignedTickets(event),
    taxConfig: getTaxConfig(event),
    showGroup: showGroupCard(state),
    template,
    navigateToMainPage,
    orderPending:
      state.navigation.reservationState === ReservationState.PENDING || order.status === OrderStatus.PENDING,
    ticketedThankYouPageLoaded,
    hasSeatingPlan: hasSeatingPlan(event),
  }
}

export const OrderSuccess = connect<{}, OrderSuccessRuntimeProps>(mapRuntime)(withTranslation()(Presentation))

export * from './interfaces'
