import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {
  getPriceOptions,
  getSelectedPriceOptionId,
  getSelectedPriceValue,
  getSelectedZoneOptionId,
  getSelectedZoneValue,
  getZoneOptions,
  isShowAccessibilityMode,
} from '../../../../../selectors/seating'
import {FiltersRuntimeProps, FiltersOwnProps} from './interfaces'
import {Filters as Presentation} from './filters'

const mapRuntime = (
  {state, actions: {selectPrice, selectLocation}}: DetailsPageAppProps,
  {t}: FiltersOwnProps,
): FiltersRuntimeProps => {
  const priceOptions = getPriceOptions(state.tickets, t)
  const zoneOptions = getZoneOptions(state.seating.plan, t)

  const selectedPriceOptionId = getSelectedPriceOptionId(state, priceOptions)
  const selectedZoneOptionId = getSelectedZoneOptionId(state, zoneOptions)

  return {
    selectPrice,
    selectLocation,
    showAccessibilityMode: isShowAccessibilityMode(state),
    priceOptions,
    zoneOptions,
    selectedPriceOptionId,
    selectedZoneOptionId,
    selectedPriceValue: getSelectedPriceValue(priceOptions, selectedPriceOptionId),
    selectedZoneValue: getSelectedZoneValue(zoneOptions, selectedZoneOptionId),
  }
}

export const Filters = connect<FiltersOwnProps, FiltersRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
