import {getInputControlMeta} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Error} from '../../commons/error'
import {Label} from '../../commons/label'
import fs from '../../fields.scss'
import c from '../../../classnames.scss'
import {InputProps} from './index'

export const Input: React.FC<InputProps> = ({
  input: {onChange, onBlur, value},
  meta: {touched, error},
  control,
  disabled,
  t,
}) => {
  const {mandatory, maxLength, name, label} = getInputControlMeta(control)

  return (
    <div data-hook={touched && error ? DH.FORM_INVALID_FIELD : ''} className={fs.field}>
      <Label
        classes={classNames(fs.customCaption, {[fs.disabledCaption]: disabled})}
        dataHook="input-caption"
        required={mandatory}
        htmlFor={name}
        caption={label}
      />
      <input
        className={classNames(c.formInputBorderColor, fs.input, {[c.formInputError]: touched && error})}
        data-hook="input-input"
        type="text"
        id={name}
        value={value}
        maxLength={maxLength}
        required={mandatory}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
      />
      <Error dataHook="input-error" error={touched && error && t(error)} />
    </div>
  )
}
