import {getFormattedMoney, isDonationTicketDefinition, minimumDonation} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {Tax} from '../tax'
import {WixFee} from '../wix-fee'
import {getFlexJustifyContentClass} from '../../../alignment'
import {DONATION_ERROR} from '../../../../../types'
import {Donation} from '../../donation'
import {checkDonationError} from '../../../../../services/donations'
import s from './price.scss'
import {PriceProps} from './index'

export const Price = ({
  t,
  className,
  ticket,
  isMobile,
  donationInputVisible,
  contentAlignment,
  changeTicketDonation,
  donation,
  donationError,
  setDonationError,
}: PriceProps) => {
  const price = getFormattedMoney(ticket.price)
  const donationTicket = isDonationTicketDefinition(ticket)

  const onDonationChange = (donation: number) => {
    changeTicketDonation({ticketId: ticket.id, donation, minimum: minimumDonation(ticket)})
  }

  const onDonationBlur = () => {
    const error = checkDonationError(ticket, donation, {checkOnlyErrors: [DONATION_ERROR.MINIMUM_NOT_REACHED]})
    if (error) {
      setDonationError({ticketId: ticket.id, error})
    }
  }

  return (
    <div className={className} role="cell">
      {donationInputVisible ? (
        <Donation
          ticket={ticket}
          value={donation}
          onChange={onDonationChange}
          onBlur={onDonationBlur}
          error={donationError}
        />
      ) : (
        <p data-hook="price" aria-label={t('mobile.thanks.price', {price})}>
          {donationTicket ? t('tickets.donation') : price}
        </p>
      )}

      <div
        className={classNames({
          [s.taxFeeContainer]: isMobile,
          [getFlexJustifyContentClass(contentAlignment)]: isMobile,
        })}
      >
        <Tax ticket={ticket} />
        <WixFee ticket={ticket} />
      </div>
    </div>
  )
}
