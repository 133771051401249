import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {LabelWithValue} from '../../../common/label-with-value'
import c from '../../../classnames.scss'
import {getPlaceInfoForOrderSummary} from '../../../../selectors/order-summary'
import s from './place.scss'
import {PlaceProps} from './index'

export const Place = ({seatingPlan, ticket, seatId}: PlaceProps) => {
  const {t} = useTranslation()
  const {section, element, area, table, place} = getPlaceInfoForOrderSummary(seatId, seatingPlan)
  const labelWithValueClassNames = {
    valueClassName: c.formSummaryColor,
    labelClassName: c.formSummaryColor,
  }

  return (
    <div className={s.container}>
      <div className={s.infoContainer}>
        <LabelWithValue label={t('seatings_sector')} value={section.title} {...labelWithValueClassNames} />
        {area ? (
          <LabelWithValue label={t('seatings_area')} value={element.title} {...labelWithValueClassNames} />
        ) : (
          <div className={s.rowAndSeat}>
            <LabelWithValue
              label={table ? t('seatings_table') : t('seatings_row')}
              value={element.title}
              {...labelWithValueClassNames}
            />
            <LabelWithValue label={t('seatings_seat')} value={place.label} {...labelWithValueClassNames} />
          </div>
        )}
      </div>
      <div className={s.ticketInfoContainer}>
        <span>{ticket.name}</span>
        <span>{getFormattedMoney(ticket.price)}</span>
      </div>
    </div>
  )
}
