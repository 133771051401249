import {saleScheduled} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {isChooseSeatMode} from '../../../../../selectors/navigation'
import {getSelectedTicketQuantity} from '../../../../../selectors/selected-tickets'
import {isSeatingCollapsedAreaTicketsEnabled} from '../../../../../../../../commons/selectors/experiments'
import {TicketAction as Presentation} from './ticket-action'
import {TicketActionOwnProps, TicketActionRuntimeProps} from './interfaces'

const mapRuntime = (
  {state, actions: {addPlaceToBasket}}: DetailsPageAppProps,
  {place}: TicketActionOwnProps,
): TicketActionRuntimeProps => {
  const ticket = place?.ticket

  return {
    selectedTicketQuantity: getSelectedTicketQuantity(state, ticket?.id, place?.id),
    chooseSeatMode: isChooseSeatMode(state),
    addPlaceToBasket,
    scheduled: ticket ? saleScheduled(ticket) : undefined,
    description: ticket.description,
    collapsedAreaTicket: isSeatingCollapsedAreaTicketsEnabled(state),
  }
}

export const TicketAction = connect<TicketActionOwnProps, TicketActionRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
