import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {getOrderRemainder} from '../../../../../selectors/seating'
import {isChooseSeatMode} from '../../../../../selectors/navigation'
import {TicketAreaCounterOwnProps, TicketAreaCounterRuntimeProps} from './interfaces'
import {TicketAreaCounter as Presentation} from './ticket-area-counter'

const mapRuntime = (
  {state, actions: {setPlaceDonationError, setSeatingError}}: DetailsPageAppProps,
  {showAccessibilityMode, place}: TicketAreaCounterOwnProps,
): TicketAreaCounterRuntimeProps => ({
  setPlaceDonationError,
  setSeatingError,
  orderRemainder: getOrderRemainder(state, place, showAccessibilityMode),
  chooseSeatMode: isChooseSeatMode(state),
})

export const TicketAreaCounter = connect<TicketAreaCounterOwnProps, TicketAreaCounterRuntimeProps>(mapRuntime)(
  Presentation,
)
