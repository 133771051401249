import {connect} from '../../../../runtime-context/context'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {getPlaceDonationError, isShowAccessibilityMode} from '../../../../../selectors/seating'
import {isChooseSeatMode} from '../../../../../selectors/navigation'
import {TicketDonationOwnProps, TicketDonationRuntimeProps} from './interfaces'
import {TicketDonation as Presentation} from './ticket-donation'

const mapRuntime = (
  {state, actions: {addPlaceDonation, setPlaceDonationError}}: DetailsPageAppProps,
  {place}: TicketDonationOwnProps,
): TicketDonationRuntimeProps => ({
  error: getPlaceDonationError(state, place.id),
  showAccessibilityMode: isShowAccessibilityMode(state),
  chooseSeatMode: isChooseSeatMode(state),
  addPlaceDonation,
  setPlaceDonationError,
})

export const TicketDonation = connect<TicketDonationOwnProps, TicketDonationRuntimeProps>(mapRuntime)(Presentation)
