import {connect} from '../../../../../../../../commons/components/runtime-context/connect'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {SeatsUnavailable as Presentation} from './seats-unavailable'
import {SeatsUnavailableRuntimeProps} from './interfaces'

const mapRuntime = ({actions: {clearReservationError}}: DetailsPageAppProps): SeatsUnavailableRuntimeProps => ({
  close: clearReservationError,
})

export const SeatsUnavailable = connect<{}, SeatsUnavailableRuntimeProps>(mapRuntime)(Presentation)
