import classNames from 'classnames'
import React from 'react'
import s from '../../fields.scss'
import {LabelProps} from './index'

export const Label: React.FC<LabelProps> = ({classes, dataHook, required, htmlFor, caption, tabIndex = -1}) => (
  <div className={classNames(s.caption, classes)} data-hook={dataHook} tabIndex={tabIndex}>
    {required && <span>*</span>}
    <label htmlFor={htmlFor}>{caption}</label>
  </div>
)
