import {getEventTitle, getFormattedFullLocation, getEventId} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {EventInfoRuntimeProps, EventInfoOwnProps} from './interfaces'
import {EventInfo as Presentation} from './event-info'

const mapRuntime = ({state: {event}}: DetailsPageAppProps): EventInfoRuntimeProps => ({
  eventId: getEventId(event),
  title: getEventTitle(event),
  location: getFormattedFullLocation(event),
})

export const EventInfo = connect<EventInfoOwnProps, EventInfoRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
