import {getLegendItems} from '../../../../../selectors/seating'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {LegendRuntimeProps, LegendOwnProps} from './interfaces'
import {Legend as Presentation} from './legend'

const mapRuntime = (
  {state, actions: {seatingMapButtonClick}}: DetailsPageAppProps,
  {t}: LegendOwnProps,
): LegendRuntimeProps => ({
  legendItems: getLegendItems(state, t),
  seatingMapButtonClick,
})

export const Legend = connect<LegendOwnProps, LegendRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
