import {isColorDark} from '@wix/wix-events-commons-statics'
import {useContext} from 'react'
import {DetailsPageRuntimeContext} from '../components/runtime-context/context'

export const useColors = () => {
  const detailsPageRuntimeContext = useContext(DetailsPageRuntimeContext)

  const getActionColor = () =>
    detailsPageRuntimeContext.host.style.siteColors.find((color: any) => color.reference === 'color-8').value

  const getSecondRsvpButtonColor = () =>
    detailsPageRuntimeContext.host.style.styleParams.colors?.secondRsvpButtonBackgroundColor?.value || getActionColor()

  const darkColor = isColorDark(getSecondRsvpButtonColor())

  return {
    darkColor,
  }
}
