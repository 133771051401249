import {Button} from 'wix-ui-tpa/Button'
import React from 'react'
import classNames from 'classnames'
import {isRoundedButton} from '../../../../selectors/settings'
import {useColors} from '../../../../hooks/colors'
import {classes} from './button-style.st.css'
import {ButtonStyleProps} from './interfaces'

export const ButtonStyle = ({
  dataHook,
  onClick,
  text,
  disabled = false,
  buttonStyle,
  fullWidth = true,
  secondary = false,
  className,
}: ButtonStyleProps) => {
  const roundedButton = isRoundedButton(buttonStyle)
  const {darkColor} = useColors()

  const getClassName = () => {
    if (secondary && roundedButton && !darkColor) {
      return classes.secondaryRoundedDark
    }
    if (secondary && !roundedButton && !darkColor) {
      return classes.secondaryDark
    }
    if (secondary && roundedButton) {
      return classes.secondaryRounded
    }
    if (secondary && !roundedButton) {
      return classes.secondary
    }
    if (roundedButton && !darkColor) {
      return classes.roundedDark
    }
    if (!roundedButton && !darkColor) {
      return classes.regularDark
    }
    if (roundedButton) {
      return classes.rounded
    }
    if (!roundedButton) {
      return classes.regular
    }
  }

  return (
    <Button
      data-hook={dataHook}
      fullWidth={fullWidth}
      upgrade
      className={classNames(classes.button, getClassName(), className)}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}
