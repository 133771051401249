import React from 'react'
import {RsvpStatus} from '@wix/events-types'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Radio} from '../radio'
import s from './responses.scss'
import {ResponsesProps} from './interfaces'

export const Responses = ({response, onChange, messages, waitlistOpen}: ResponsesProps) => {
  const onChangeResponse = (response: wix.events.rsvp.RsvpStatus) =>
    onChange(response === RsvpStatus.YES && waitlistOpen ? RsvpStatus.WAITING : response)

  const handleResponsesKeyDown = (event: React.KeyboardEvent) => {
    switch (event.keyCode) {
      case 37: // arrow left
      case 38: // arrow up
      case 39: // arrow right
      case 40: // arrow down
        event.preventDefault()
        return onChange(response === RsvpStatus.NO ? RsvpStatus.YES : RsvpStatus.NO)
      default:
        return
    }
  }

  return (
    <div className={s.responses} tabIndex={0} onKeyDown={handleResponsesKeyDown}>
      <div className={s.response}>
        <Radio
          name="response"
          dataHook="yes-radio-button"
          value={messages.rsvp.rsvpYesOption}
          checked={response !== RsvpStatus.NO}
          onChange={() => onChangeResponse(RsvpStatus.YES)}
        />
      </div>
      <div className={s.response}>
        <Radio
          name="response"
          dataHook={DH.NO_RADIO_BUTTON}
          value={messages.rsvp.rsvpNoOption}
          checked={response === RsvpStatus.NO}
          onChange={() => onChangeResponse(RsvpStatus.NO)}
        />
      </div>
    </div>
  )
}
