import React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {classes} from './label-with-value.st.css'
import {LabelWithValueProps} from './index'

export const LabelWithValue = ({label, labelClassName, valueClassName, value}: LabelWithValueProps) =>
  value ? (
    <Text tagName="p" className={classes.labelWithValue}>
      <Text className={classNames(classes.smallText, labelClassName ?? classes.smallTextColor)}>{label}</Text>
      <Text className={classNames(classes.value, valueClassName ?? classes.valueColor)}>{value}</Text>
    </Text>
  ) : null
