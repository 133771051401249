import {getFormMessages} from '@wix/wix-events-commons-statics'
import {connect} from '../../runtime-context/context'
import {getSelectedTicketsArray} from '../../../selectors/selected-tickets'
import {DetailsPageAppProps} from '../../app/interfaces'
import {getTicketDetails, getExpandedTicketIndex, isAllTicketDetailsValid} from '../../../selectors/checkout'
import {TicketsDetailsOwnProps, TicketsDetailsRuntimeProps} from './interfaces'
import {TicketsDetails as Presentation} from './tickets-details'

const mapRuntime = ({
  state,
  actions: {nextFormClicked, setExpandedTicketIndex},
}: DetailsPageAppProps): TicketsDetailsRuntimeProps => {
  const selectedTickets = getSelectedTicketsArray(state)
  return {
    selectedTickets,
    details: selectedTickets.map((_, index) => getTicketDetails(state, index)),
    allTicketDetailsValid: isAllTicketDetailsValid(state),
    messages: getFormMessages(state.event),
    expandedTicketIndex: getExpandedTicketIndex(state),
    nextFormClicked,
    setExpandedTicketIndex,
  }
}

export const TicketsDetails = connect<TicketsDetailsOwnProps, TicketsDetailsRuntimeProps>(mapRuntime)(Presentation)
