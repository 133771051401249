import React from 'react'
import classNames from 'classnames'
import {addCircularTabFocus, focusElement} from '@wix/wix-events-commons-statics'
import {Header} from '../common/header'
import {CloseButton} from '../common/close-button'
import {ModalProps} from './interfaces'
import s from './modal.scss'

export const Modal = ({
  children,
  closeModal,
  skin,
  closeButtonAsButton,
  className,
  dataHook,
  firstChildSelector,
  lastChildSelector,
  returnFocusElement,
  title,
  withoutPadding,
  tabs,
  activeTabIndex,
  onTabClick,
}: ModalProps) => {
  const grey = skin === 'grey'
  const withTitle = Boolean(title)

  const getPaddingBottom = () => {
    if (withTitle || withoutPadding || grey) {
      return 0
    }

    return 52
  }

  React.useEffect(() => {
    if (firstChildSelector && lastChildSelector) {
      addCircularTabFocus(firstChildSelector, lastChildSelector)
      focusElement({selector: firstChildSelector, canFocus: true})
    }
    return () => (returnFocusElement ? focusElement({selector: returnFocusElement, canFocus: true}) : null)
  }, [])

  return (
    <>
      <div className={s.underlay} />
      <div
        className={classNames(s.container, className, {
          [s.grey]: grey,
          [s.noPadding]: withTitle || withoutPadding,
        })}
        style={{paddingBottom: getPaddingBottom()}}
        data-hook={dataHook}
      >
        {withTitle ? (
          <Header
            title={title}
            closeButtonAsButton={closeButtonAsButton}
            closeModal={closeModal}
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            onTabClick={onTabClick}
          />
        ) : null}
        {children}
        {!withTitle ? <CloseButton closeButtonAsButton={closeButtonAsButton} closeModal={closeModal} /> : null}
      </div>
    </>
  )
}
