import React from 'react'
import {Spinner} from 'wix-ui-tpa/Spinner'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {SPINNER_TYPES} from 'wix-ui-tpa/dist/src/components/Spinner/types'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {classes} from './loader.st.css'

export const Loader = () => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  return (
    <div className={classes.container} aria-labelledby="content">
      <div className={classNames(classes.content, {[classes.desktopContent]: !isMobile})}>
        <div className={classes.text} id="content">
          <Text
            data-hook={DH.MODAL_TITLE}
            tagName="h2"
            className={classNames(classes.title, {[classes.mobileTitle]: isMobile})}
          >
            {t('seatings_loader_title')}
          </Text>
          <Text className={classes.subtitle}>{t('seatings_loader_subtitle')}</Text>
        </div>
        <Spinner type={SPINNER_TYPES.slim} className={classes.spinner} />
      </div>
    </div>
  )
}
