import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Divider} from '../../../../common/divider'
import {calculateSubPrice} from '../../../../../../services/payment'
import {getTicketPriceText} from '../../../../../../selectors/order-success'
import s from './ticket-info.scss'
import {TicketInfoProps} from './index'

export const TicketInfo = ({item}: TicketInfoProps) => {
  const {t} = useTranslation()

  const getTicketAriaLabel = (priceText: string) => {
    const name = `${t('ticketsThankYou.ticketName')}: ${item.name}`
    const price = t('mobile.thanks.price', {price: getTicketPriceText(item.price, t)})
    const qty = t('mobile.thanks.quantity', {quantity: item.quantity})
    const total = `${t('mobile.thanks.total')} ${priceText}`

    return `${name}, ${price}, ${qty}, ${total}`
  }

  const price = getTicketPriceText(
    {
      amount: calculateSubPrice(Number(item.price.amount), item.quantity).toString(),
      currency: item.price.currency,
    },
    t,
  )

  return (
    <>
      <div className={s.container} data-hook={DH.ORDER_SUCCESS_SUMMARY} aria-label={getTicketAriaLabel(price)}>
        <div className={s.row}>{item.name}</div>
        <div className={s.row}>{t('mobile.thanks.quantity', {quantity: item.quantity})}</div>
        <div className={s.row}>
          <div className={s.rowLeft}>{t('mobile.thanks.price', {price: getTicketPriceText(item.price, t)})}</div>
          <div className={s.rowRight}>{price}</div>
        </div>
      </div>
      <Divider />
    </>
  )
}
