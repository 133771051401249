import React from 'react'
import cn from '../../../classnames.scss'
import s from './container.scss'
import {ContainerProps} from './index'

export const Container: React.FC<ContainerProps> = ({children, dataHook}) => (
  <div className={cn.formBackgroundColor}>
    <main className={s.container} data-hook={dataHook}>
      {children}
    </main>
  </div>
)
