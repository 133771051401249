import {connect} from '../../../../../../../../commons/components/runtime-context/connect'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {SomethingWentWrong as Presentation} from './something-went-wrong'
import {SomethingWentWrongRuntimeProps} from './interfaces'

const mapRuntime = ({
  actions: {checkout, clearReservationError},
}: DetailsPageAppProps): SomethingWentWrongRuntimeProps => ({
  close: clearReservationError,
  tryAgainClick: checkout,
})

export const SomethingWentWrong = connect<{}, SomethingWentWrongRuntimeProps>(mapRuntime)(Presentation)
