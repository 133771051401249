import React, {useState, useEffect} from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {classes} from './landscape-modal.st.css'

export const LandscapeModal = () => {
  const {t} = useTranslation()
  const [landscape, setLandscape] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia('(orientation: landscape)').matches) {
        setLandscape(true)
      } else {
        setLandscape(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return landscape ? (
    <div className={classes.container}>
      <Text className={classes.content} tagName="H2">
        {t('seatings_mobile_landscape')}
      </Text>
    </div>
  ) : null
}
