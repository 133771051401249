import React, {useCallback, useState} from 'react'
import classNames from 'classnames'
import {TicketInfo} from '../ticket/ticket-info'
import s from './seating-popover.scss'
import {SeatingPopoverProps} from './interfaces'

export const SeatingPopover = ({popoverData}: SeatingPopoverProps) => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  const {boundingClientRect, containerBoundingClientRect} = popoverData
  const spaceBetweenTooltipAndSeat = 10
  const shouldOpenBottom = boundingClientRect.top - height - spaceBetweenTooltipAndSeat < 10
  const top = shouldOpenBottom
    ? boundingClientRect.bottom + spaceBetweenTooltipAndSeat - containerBoundingClientRect.top
    : boundingClientRect.top - height - spaceBetweenTooltipAndSeat - containerBoundingClientRect.top
  const left = (boundingClientRect.left + boundingClientRect.right) / 2 - containerBoundingClientRect.left - width / 2

  const setWidthAndHeight = useCallback(e => {
    setHeight(e?.clientHeight)
    setWidth(e?.offsetWidth)
  }, [])

  return (
    <div
      key={popoverData?.info?.id}
      className={classNames(s.container, {[s.bottom]: shouldOpenBottom})}
      style={{
        opacity: height ? 1 : 0,
        top,
        left,
      }}
      ref={setWidthAndHeight}
    >
      <TicketInfo place={popoverData.info} inPopover />
    </div>
  )
}
