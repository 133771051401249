import {connect} from '../../../runtime-context/context'
import {getInvoice} from '../../../../selectors/placed-order'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {getSelectedTicketsQuantity} from '../../../../selectors/selected-tickets'
import {isEventPreview} from '../../../../selectors/navigation'
import {Summary as Presentation} from './summary'
import {SummaryRuntimeProps, SummaryOwnProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): SummaryRuntimeProps => ({
  selectedTickets: getSelectedTicketsQuantity(state),
  invoice: getInvoice(state),
  eventPreview: isEventPreview(state),
})

export const Summary = connect<SummaryOwnProps, SummaryRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
