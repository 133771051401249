import React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {classes} from './header.st.css'

export const Header = () => {
  const {t} = useTranslation()

  return (
    <Text tagName="h1" className={classes.title}>
      {t('seatings_accessibility_title')}
    </Text>
  )
}
