import {isDonationTicketDefinition, isDonationLessThanMinimum} from '@wix/wix-events-commons-statics'
import {DONATION_ERROR} from '../types'

interface CheckDonationErrorOptions {
  checkOnlyErrors: DONATION_ERROR[]
}

export const isDonationValid = (ticket: wix.events.ticketing.TicketDefinition, donation: number) => {
  const error = checkDonationError(ticket, donation)
  return !error
}

export const checkDonationError = (
  ticket: wix.events.ticketing.TicketDefinition,
  donation: number,
  options?: CheckDonationErrorOptions,
): DONATION_ERROR => {
  const {checkOnlyErrors} = options || {}

  if (!isDonationTicketDefinition(ticket)) {
    return
  }
  let error: DONATION_ERROR
  if (donation === undefined) {
    error = DONATION_ERROR.EMPTY_DONATION
  } else if (donation !== undefined && isDonationLessThanMinimum(ticket, donation)) {
    error = DONATION_ERROR.MINIMUM_NOT_REACHED
  }
  return error && (!checkOnlyErrors || checkOnlyErrors.includes(error)) ? error : undefined
}
