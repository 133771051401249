import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {getTicketsWithoutTax} from '../../../../../../../../commons/selectors/tickets'
import {Divider} from '../../../common/divider'
import {TicketWithSeating} from '../../../../../../../../commons/components/ticket-with-seating'
import s from './order-with-seating.scss'
import {OrderWithSeatingProps} from './interfaces'

const TableHeader = ({t}) => (
  <div role="rowgroup">
    <div role="row" className={classNames(s.row)}>
      <div className={s.colTicket} role="columnheader">
        <span>{t('order.header.ticket-type')}</span>
      </div>
      <div className={s.colSeatInfo} role="columnheader">
        <span>{t('order.header.seatInfo')}</span>
      </div>
      <div className={s.colTotal} role="columnheader">
        <span>{t('order.header.price')}</span>
      </div>
    </div>
  </div>
)

export const OrderWithSeating = ({order}: OrderWithSeatingProps) => {
  const tickets = getTicketsWithoutTax(order)
  const {t} = useTranslation()

  return (
    <div data-hook={DH.ORDER_DETAILS} role="table">
      <Divider />
      <TableHeader t={t} />
      <Divider />
      {tickets.map(item => (
        <TicketWithSeating key={item.ticketNumber} ticket={item} />
      ))}
    </div>
  )
}
