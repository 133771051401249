import {
  getGuestNamesCaptions,
  getGuestNamesMaxLength,
  getInputControlMeta,
  isSingleGuestControl,
} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Checkbox} from '../../commons/checkbox'
import {Error} from '../../commons/error'
import {Label} from '../../commons/label'
import {Select} from '../../commons/select'
import fs from '../../fields.scss'
import c from '../../../classnames.scss'
import s from './guests-control.scss'
import {GuestsControlProps, GuestsControlState, GuestName} from './index'

export class GuestsControl extends React.Component<GuestsControlProps, GuestsControlState> {
  state = {
    touched: [],
  }

  componentDidMount() {
    if (!this.props.input.value) {
      this.onChange(this.getGuestCount(), this.getGuestNames())
    }
  }

  toggleGuestSelected = () => this.changeGuestsCount(this.getGuestCount() > 0 ? 0 : 1)

  getGuestCount = () => this.props.input.value.additionalGuests || 0

  getGuestNames = () => this.props.input.value.guestNames || []

  changeGuestsCount(guestsCount: number) {
    let guestNames = this.getGuestNames()

    if (guestNames.length > guestsCount) {
      guestNames = guestNames.slice(0, guestsCount)
    }

    this.onChange(guestsCount, guestNames)
  }

  changeGuestName(idx: number, key: string, value: string) {
    const guestNames = [...this.getGuestNames()]

    if (!guestNames[idx]) {
      guestNames[idx] = {}
    }

    guestNames[idx] = {
      ...guestNames[idx],
      [key]: value,
    }

    this.onChange(this.getGuestCount(), guestNames)
  }

  onChange(guestsCount: number, guestNames: GuestName[]) {
    const {input, control} = this.props
    const {name} = getInputControlMeta(control)

    input.onChange({
      [name]: guestsCount,
      guestNames: _.cloneDeep(guestNames),
    })
  }

  setTouched(idx: number, key: string) {
    const {touched} = this.state

    touched[idx] = touched[idx] || {}

    if (!touched[idx][key]) {
      touched[idx][key] = true
      this.setState({touched})
    }
  }

  isTouched(idx: number, key: string) {
    const {touched} = this.state
    return touched[idx]?.[key] || this.props.meta.touched
  }

  isError(idx: number, key: string) {
    const {error} = this.props.meta
    return this.isTouched(idx, key) && error?.[idx]?.[key]
  }

  renderGuest(idx: number = 0) {
    const {
      meta: {error},
      control,
      t,
    } = this.props
    const {firstName, lastName} = getGuestNamesCaptions(control)
    const maxLength = getGuestNamesMaxLength(control)
    const guestNames = this.getGuestNames()
    const firstNameError = this.isError(idx, 'firstName')
    const lastNameError = this.isError(idx, 'lastName')

    return (
      <div data-hook={firstNameError || lastNameError ? DH.FORM_INVALID_FIELD : ''} className={s.guest} key={idx}>
        <div className={s.guestName}>
          <Label
            dataHook="guests-control-caption"
            required={true}
            htmlFor={`guestFirstName${idx}`}
            caption={firstName}
          />
          <input
            className={classNames(c.formInputBorderColor, fs.input, {[c.formInputError]: firstNameError})}
            data-hook="guests-control-input"
            type="text"
            id={`guestFirstName${idx}`}
            value={guestNames[idx]?.firstName}
            maxLength={maxLength}
            required={true}
            onBlur={() => this.setTouched(idx, 'firstName')}
            onChange={event => this.changeGuestName(idx, 'firstName', event.target.value)}
          />
          <Error dataHook="input-error" error={firstNameError && t(error[idx].firstName)} />
        </div>
        <div className={s.guestName}>
          <Label dataHook="guests-control-caption" required={true} htmlFor={`guestLastName${idx}`} caption={lastName} />
          <input
            className={classNames(c.formInputBorderColor, fs.input, {[c.formInputError]: lastNameError})}
            data-hook="guests-control-input"
            type="text"
            id={`guestLastName${idx}`}
            value={guestNames[idx]?.lastName}
            maxLength={maxLength}
            required={true}
            onBlur={() => this.setTouched(idx, 'lastName')}
            onChange={event => this.changeGuestName(idx, 'lastName', event.target.value)}
          />
          <Error dataHook="input-error" error={lastNameError && t(error[idx].lastName)} />
        </div>
      </div>
    )
  }

  renderSingleGuest() {
    const {label, mandatory} = getInputControlMeta(this.props.control)
    const guestsCount = this.getGuestCount()

    return (
      <div data-hook={DH.FORM_ADDITIONAL_GUEST_SELECT}>
        <Checkbox value={label} checked={!!guestsCount} onChange={this.toggleGuestSelected} />
        <div className={s.guests}>{mandatory && guestsCount ? this.renderGuest() : null}</div>
      </div>
    )
  }

  renderMultipleGuests() {
    const {label, mandatory, options} = getInputControlMeta(this.props.control)
    const guestsCount = this.getGuestCount()

    return (
      <div data-hook={DH.FORM_ADDITIONAL_GUEST_SELECT}>
        <Label classes={s.caption} dataHook={DH.FORM_GUEST_CONTROL_LABEL} required={false} caption={label} />
        <div className={s.select}>
          <Select
            options={options}
            value={guestsCount}
            ariaLabel={label}
            onChange={value => this.changeGuestsCount(Number(value))}
          />
        </div>
        <div className={s.guests}>
          {mandatory && guestsCount ? _.range(guestsCount).map(value => this.renderGuest(value)) : null}
        </div>
      </div>
    )
  }

  render() {
    return isSingleGuestControl(this.props.control) ? this.renderSingleGuest() : this.renderMultipleGuests()
  }
}
