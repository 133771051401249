import React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {isDonationTicketDefinition, isAvailableDonationTicket} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {TicketDonation} from '../ticket-donation'
import {classes} from './ticket-price.st.css'
import {TicketPriceProps} from './interfaces'

export const TicketPrice = ({place, readonly}: TicketPriceProps) => {
  const {t} = useTranslation()

  if (!place.ticket) {
    return null
  }

  const {ticketPrice, ticket} = place
  const isDonation = isDonationTicketDefinition(ticket)
  const showDonationInput = isAvailableDonationTicket(ticket) && !readonly

  return showDonationInput ? (
    <TicketDonation place={place} />
  ) : (
    <Text className={classes.ticketPrice}>{isDonation ? t('tickets.donation') : ticketPrice}</Text>
  )
}
