import {isMobile} from '../../../../../../commons/selectors/environment'
import {getButtonStyle} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {RsvpInfoButtonOwnProps, RsvpInfoButtonRuntimeProps} from './interfaces'
import {RsvpInfoButton as Presentation} from './rsvp-info-button'

const mapRuntime = ({state}: DetailsPageAppProps): RsvpInfoButtonRuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
  mobile: isMobile(state),
})

export const RsvpInfoButton = connect<RsvpInfoButtonOwnProps, RsvpInfoButtonRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
