import {connect} from '../../../runtime-context/context'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {getBuyerDetails, getTicketDetails, getUseBuyerDetails} from '../../../../selectors/checkout'
import {getSelectedTicketsQuantity} from '../../../../selectors/selected-tickets'
import {TicketDetailsOwnProps, TicketDetailsRuntimeProps} from './interfaces'
import {TicketDetails as Presentation} from './ticket-details'

const mapRuntime = (
  {state, actions: {setUseBuyerDetails, setTicketDetails}}: DetailsPageAppProps,
  ownProps: TicketDetailsOwnProps,
): TicketDetailsRuntimeProps => ({
  selectedTicketsQuantity: getSelectedTicketsQuantity(state),
  details: getTicketDetails(state, ownProps.index),
  buyerDetails: getBuyerDetails(state),
  useBuyerDetails: getUseBuyerDetails(state),
  setUseBuyerDetails,
  setTicketDetails,
})

export const TicketDetails = connect<TicketDetailsOwnProps, TicketDetailsRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
