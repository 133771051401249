import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {isChooseSeatMode} from '../../../../../selectors/navigation'
import {getSelectedTicketsWithPlaceInfo, getTotalSelectedTicketsQuantity} from '../../../../../selectors/seating'
import {isSeatingCollapsedAreaTicketsEnabled} from '../../../../../../../../commons/selectors/experiments'
import {SidebarOwnProps, SidebarRuntimeProps} from './interfaces'
import {Sidebar as Presentation} from './sidebar'

const mapRuntime = ({state}: DetailsPageAppProps): SidebarRuntimeProps => {
  const placesInBasket = getSelectedTicketsWithPlaceInfo(state)

  return {
    showAccessibilityMode: state.seating.showAccessibilityMode,
    chooseSeatMode: isChooseSeatMode(state),
    placesInBasketCount: isSeatingCollapsedAreaTicketsEnabled(state)
      ? getTotalSelectedTicketsQuantity(state)
      : placesInBasket.length,
    placesInBasket,
  }
}

export const Sidebar = connect<SidebarOwnProps, SidebarRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
