import {getInputControlMeta} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Error} from '../../commons/error'
import {Label} from '../../commons/label'
import fs from '../../fields.scss'
import c from '../../../classnames.scss'
import s from './textarea.scss'
import {TextareaProps} from './index'

export const Textarea: React.FC<TextareaProps> = ({
  input: {onChange, onBlur, value},
  meta: {touched, error},
  control,
  t,
}) => {
  const {name, label, maxLength, mandatory} = getInputControlMeta(control)

  return (
    <div data-hook={touched && error ? DH.FORM_INVALID_FIELD : ''} className={fs.field}>
      <Label
        classes={fs.customCaption}
        dataHook="textarea-caption"
        required={mandatory}
        htmlFor={name}
        caption={label}
      />
      <textarea
        className={classNames(c.formInputBorderColor, fs.input, s.textarea, {[c.formInputError]: touched && error})}
        data-hook="textarea-textarea"
        id={name}
        value={value}
        maxLength={maxLength}
        required={mandatory}
        onBlur={onBlur}
        onChange={onChange}
      />
      <Error dataHook="textarea-error" error={touched && error && t(error)} />
    </div>
  )
}
