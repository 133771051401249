import React from 'react'
import {EventDetailsButtonStyle} from '@wix/wix-events-commons-statics'
import {Tooltip} from 'wix-ui-tpa/Tooltip'
import {Spinner} from 'wix-ui-tpa/Spinner'
import {isHollowButton, isRoundedButton} from '../../selectors/settings'
import c from '../classnames.scss'
import s from './form-button.scss'
import {classes} from './form-button.st.css'
import {FormButtonProps} from './interfaces'

const getButtonClasses = (buttonStyle: EventDetailsButtonStyle, disabled: boolean, mobile: boolean) => {
  const buttonClasses = [
    s.button,
    isRoundedButton(buttonStyle) ? c.formButtonRoundedBorderRadius : c.formButtonBorderRadius,
  ]

  if (mobile) {
    buttonClasses.push(s.mobileButton)
  }

  if (isHollowButton(buttonStyle)) {
    buttonClasses.push(c.formHollowButtonFont, c.formHollowButtonColor, c.formButtonBorder)
  } else {
    buttonClasses.push(c.formFullButtonFont, c.formFullButtonColor, c.formButtonBackgroundColor)
  }

  if (disabled) {
    buttonClasses.push(s.disabled)
  }

  return buttonClasses.join(' ')
}

export const FormButton: React.FC<FormButtonProps> = ({
  mobile,
  text,
  buttonStyle,
  type = 'button',
  dataHook,
  width = '100%',
  disabled,
  onClick,
  previewTooltipText,
  spinner,
}) => {
  const content = spinner ? (
    <Spinner
      className={isHollowButton(buttonStyle) ? classes.hollowButtonSpinner : classes.fullButtonSpinner}
      diameter={20}
    />
  ) : (
    text
  )

  if (previewTooltipText) {
    return (
      <Tooltip content={previewTooltipText}>
        <button
          type="button"
          data-hook={dataHook}
          className={getButtonClasses(buttonStyle, disabled, mobile)}
          style={{width}}
        >
          {content}
        </button>
      </Tooltip>
    )
  }

  return (
    <button
      data-hook={dataHook}
      className={getButtonClasses(buttonStyle, disabled, mobile)}
      style={{width}}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {content}
    </button>
  )
}
