import {NEW_FORM_FIELD_VALIDATION} from '@wix/wix-events-commons-statics'
import {InputControlType, RsvpStatus} from '@wix/events-types'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import {Field, Fields, Form as ReduxForm} from 'redux-form'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {RegFormData} from '../../types'
import {FormSubmitButton} from '../form-submit-button'
import s from './form.scss' // these styles should be overwritten by component styles
import {FormProps} from './interfaces'
import {FORM_DESKTOP_COMPONENTS} from './utils'
import {Responses} from './commons/responses'

export const Form = ({
  hideButton,
  messages,
  noResponseEnabled,
  reservationId,
  handleSubmit,
  hideAdditionalFields,
  controls,
  t,
  disabledFields,
  isTicketForm,
  isFullButton,
  isLastTicket,
  hasPolicies,
  isSubmitDisabled,
  ticketed,
  draftEvent,
  waitlistOpen,
  changeResponse,
  resetRsvpError,
  onFormSubmit,
  response,
}: FormProps) => {
  useEffect(() => {
    changeResponse(waitlistOpen ? RsvpStatus.WAITING : RsvpStatus.YES)
    resetRsvpError()
  }, [])

  const submit = async (values: RegFormData) => {
    const customData = reservationId ? {reservation: reservationId} : {response}
    const data = {...customData, ...getRsvpData(values)}
    return onFormSubmit(data)
  }

  const getRsvpData = (values: RegFormData) => {
    const data = {} as RegFormData

    getControls().forEach(({name}) => {
      if (name === 'name') {
        data.firstName = values.firstName
        data.lastName = values.lastName
      } else if (name === 'email') {
        data.email = values.email.trim()
      } else if (name === 'additionalGuests') {
        data.additionalGuests = values.additionalGuests.additionalGuests
        data.guestNames = values.additionalGuests.guestNames.map(guest => `${guest.firstName} ${guest.lastName}`)
      } else {
        data[name] = values[name]
      }
    })

    return data
  }

  const getControls = () => {
    return response === RsvpStatus.YES && !hideAdditionalFields ? controls : controls.filter(control => control.system)
  }

  const getControl = (control: wix.events.form.InputControl) => {
    const controlType = control.type || InputControlType.INPUT
    const Component = FORM_DESKTOP_COMPONENTS[controlType]
    const props: any = {t, control, disabled: disabledFields?.includes(control.name)}

    if (controlType === InputControlType.NAME) {
      return <Fields names={['firstName', 'lastName']} component={Component} props={props} />
    }

    return (
      <Field
        name={control.name}
        component={Component}
        props={props}
        validate={NEW_FORM_FIELD_VALIDATION[controlType](control)}
      />
    )
  }

  const shouldFormButtonShow = !hideButton && !isTicketForm
  const shouldTicketFormButtonShow = !hideButton && isTicketForm && !isLastTicket

  const formButtonText = ticketed
    ? messages.checkout.submitActionLabel || messages.rsvp.submitActionLabel
    : hasPolicies
    ? t('mobile.checkout.next')
    : messages.rsvp.submitActionLabel

  return (
    <ReduxForm data-hook="form" className={s.form} onSubmit={handleSubmit(submit)} noValidate>
      {noResponseEnabled && !reservationId && (
        <Responses response={response} onChange={changeResponse} messages={messages} waitlistOpen={waitlistOpen} />
      )}
      {getControls().map(control => (
        <div key={control.name} data-hook="form-field">
          {getControl(control)}
        </div>
      ))}
      {shouldFormButtonShow && (
        <FormSubmitButton
          text={formButtonText}
          dataHook={DH.FORM_BUTTON}
          disabled={isSubmitDisabled}
          previewTooltipText={draftEvent ? t('draftEventPreviewTooltip') : null}
          t={t}
        />
      )}
      {shouldTicketFormButtonShow && (
        <button className={classNames(s.formButton, {[s.fullButton]: isFullButton})} type="submit">
          {t('mobile.checkout.ticket.nextTicket')}
        </button>
      )}
    </ReduxForm>
  )
}
