import {hasSeatingPlan, isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import {SelectedTickets, State, TicketWithPlaceInfo} from '../types'
import {isSeatingCollapsedAreaTicketsEnabled} from '../../../../commons/selectors/experiments'
import {isDonationValid} from '../services/donations'
import {
  getPlaceInfo,
  getSelectedTicketsQuantities,
  getSelectedTicketsWithPlaceInfo,
  getTicketsToPlaces,
} from './seating'
import {getTicketOrderIndexById, getTicketById} from './tickets'

export const getSelectedTickets = (state: State): SelectedTickets =>
  hasSeatingPlan(state.event) ? getSelectedTicketsQuantities(state) : state.selectedTickets

export const getSelectedTicketsQuantity = (state: State) => {
  const selectedTickets = getSelectedTickets(state)
  return Object.keys(selectedTickets).reduce(
    (sum: number, ticketId: string) => selectedTickets[ticketId].quantity + sum,
    0,
  )
}

export const areSelectedDonationsValid = (state: State) => {
  const selectedTickets = getSelectedTickets(state)
  return Object.keys(selectedTickets).reduce(
    (valid: boolean, ticketId: string) => valid && isSelectedTicketDonationValid(state, ticketId),
    true,
  )
}

export const isSelectedTicketDonationValid = (state: State, ticketId: string) => {
  const selectedTickets = getSelectedTickets(state)
  const ticket = getTicketById(state.tickets, ticketId)
  if (selectedTickets[ticketId].quantity && isDonationTicketDefinition(ticket)) {
    const donation = selectedTickets[ticketId].donation
    return isDonationValid(ticket, donation)
  }
  return true
}

export const getSelectedTicketQuantity = (state: State, ticketId: string, seatId?: string) => {
  if (seatId) {
    if (isSeatingCollapsedAreaTicketsEnabled(state)) {
      return (
        getSelectedTicketsWithPlaceInfo(state).find(place => place.ticket?.id === ticketId && place.id === seatId)
          ?.quantity ?? 0
      )
    } else {
      return getTicketsToPlaces(state)[ticketId]?.filter(id => id === seatId).length
    }
  }
  return state.selectedTickets[ticketId]?.quantity ?? 0
}

export const getSelectedTicketDonation = (state: State, ticketId: string) => {
  return state.selectedTickets[ticketId]?.donation
}

export const getSelectedTicketDonationError = (state: State, ticketId: string) => {
  return state.selectedTickets[ticketId]?.donationError
}

export const getSortedSelectedTickets = (state: State) => {
  const selectedTickets = getSelectedTickets(state)
  const nonZero = (ticketDefId: string) => Boolean(selectedTickets[ticketDefId]?.quantity)

  const byTicketOrder = (first: string, second: string) =>
    getTicketOrderIndexById(state.tickets, first) - getTicketOrderIndexById(state.tickets, second)

  return Object.keys(selectedTickets)
    .filter(nonZero)
    .sort(byTicketOrder)
    .reduce(
      (sortedSelectedTickets: SelectedTickets, id: string) => ({
        ...sortedSelectedTickets,
        [id]: selectedTickets[id],
      }),
      {},
    )
}

export const getSelectedTicketsArray = (state: State): TicketWithPlaceInfo[] =>
  Object.entries(getSortedSelectedTickets(state)).reduce((tickets, [ticketDefId, {quantity}]) => {
    const ticket = getTicketById(state.tickets, ticketDefId)
    if (hasSeatingPlan(state.event)) {
      const ticketsToPlaces = getTicketsToPlaces(state)
      tickets.push(
        ...ticketsToPlaces[ticket.id].map(seatId => ({...ticket, place: getPlaceInfo(state.seating.places, seatId)})),
      )
    } else {
      tickets.push(...Array(quantity).fill(ticket))
    }
    return tickets
  }, [])

export const getTotalOrderRevenue = (state: State) => {
  const selectedTickets = getSelectedTickets(state)

  return Object.keys(selectedTickets).reduce(
    (sum, ticketId) =>
      sum + Number(getTicketById(state.tickets, ticketId).price.amount) * selectedTickets[ticketId].quantity,
    0,
  )
}

export const getNonFreeSelectedTicketsCount = (state: State) => {
  const selectedTickets = getSelectedTickets(state)

  return Object.keys(selectedTickets)
    .filter(ticketId => state.tickets.find(ticket => ticket.id === ticketId && !ticket.free))
    .reduce((sum, ticketId) => sum + selectedTickets[ticketId].quantity, 0)
}
