import {State} from '../types'
import {RESERVATION_ERROR} from '../reducers/reservation'
import {isSeatingModalOpen} from './modals'

export const getReservationId = (state: State) => state.reservation.data?.id

export const getReservationExpiration = (state: State) => state.reservation.data?.expires

export const getReservationError = (state: State) => state.reservation.error

export const isReservationUnavailable = (state: State) => getReservationError(state) && !isSeatingModalOpen(state)

export const isSeatingPlaceReservedError = (state: State) =>
  getReservationError(state) === RESERVATION_ERROR.PLACE_RESERVED

export const getReservationTicketIds = (state: State) => []
// TODO check this, API does not return reservations at all
// state.reservation.data?.reservations?.map(reservation => reservation.ticket.id) ?? []
