import {getEventId} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {connect} from '../../runtime-context/context'
import {getReservationExpiration, getReservationId} from '../../../selectors/reservation'
import {DetailsPageAppProps} from '../../app/interfaces'
import {getFormButtonStyle} from '../../../selectors/settings'
import {isMobile, isEditor} from '../../../../../../commons/selectors/environment'
import {DesktopCheckout as DesktopCheckoutPresentation} from './desktop-checkout'
import {DesktopCheckoutRuntimeProps} from './interfaces'

const mapRuntime = ({
  state,
  actions: {openTimeExpiredModal, cancelReservation, formPageLoaded, closeNativeModal},
}: DetailsPageAppProps): DesktopCheckoutRuntimeProps => ({
  eventId: getEventId(state.event),
  buttonStyle: getFormButtonStyle(state.component.settings),
  reservationId: getReservationId(state),
  reservationExpiration: getReservationExpiration(state),
  isMobile: isMobile(state),
  isEditor: isEditor(state),
  formPageLoaded,
  cancelReservation,
  openTimeExpiredModal,
  closeNativeModal,
})

export const DesktopCheckout = connect<{}, DesktopCheckoutRuntimeProps>(mapRuntime)(
  withTranslation()(DesktopCheckoutPresentation),
)
