import React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {ButtonStyle} from '../../../button-style'
import {classes} from './empty-state.st.css'
import {EmptyStateProps} from './index'

export const EmptyState = ({onButtonClick}: EmptyStateProps) => {
  const {t} = useTranslation()

  React.useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.EMPTY_STATE_TITLE)})
  }, [])

  return (
    <div className={classes.container}>
      <Text tagName="h2" className={classes.title} data-hook={DH.EMPTY_STATE_TITLE}>
        {t('seating_accessibilityMode_emptyState_title')}
      </Text>
      <Text tagName="p" className={classes.description}>
        {t('seating_accessibilityMode_emptyState_description')}
      </Text>
      <ButtonStyle
        text={t('seating_accessibilityMode_emptyState_action')}
        dataHook={DH.RESET_FILTERS}
        onClick={onButtonClick}
        secondary={true}
        fullWidth={false}
      />
    </div>
  )
}
