import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {canRetryFormSubmit, getFormSubmitError, getLastFormErrorTime} from '../../selectors/form-submit-errors'
import {FormSubmitButton as Presentation} from './form-submit-button'
import {FormSubmitButtonRuntimeProps, FormSubmitButtonOwnProps} from './interfaces'

const mapRuntime = (
  {state, actions: {toggleAgreePolicies, openPolicyModal}}: DetailsPageAppProps,
  {t}: FormSubmitButtonOwnProps,
): FormSubmitButtonRuntimeProps => {
  return {
    error: getFormSubmitError(state, t),
    canRetrySubmit: canRetryFormSubmit(state),
    errorTime: getLastFormErrorTime(state),
  }
}

export const FormSubmitButton = connect<FormSubmitButtonOwnProps, FormSubmitButtonRuntimeProps>(mapRuntime)(
  Presentation,
)
export * from './interfaces'
