import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/context'
import {getTicketById} from '../../../../../../selectors/tickets'
import {CustomDatesLabelRuntimeProps, CustomDatesLabelOwnProps} from './interfaces'
import {CustomDatesLabel as Presentation} from './custom-dates-label'

const mapRuntime = ({state}: DetailsPageAppProps, {place}: CustomDatesLabelOwnProps): CustomDatesLabelRuntimeProps => ({
  ticket: getTicketById(state.tickets, place?.ticket?.id),
})

export const CustomDatesLabel = connect<CustomDatesLabelOwnProps, CustomDatesLabelRuntimeProps>(mapRuntime)(
  withTranslation()(Presentation),
)
export * from './interfaces'
