import React, {useState, useEffect} from 'react'
import s from '../form/form.scss'
import {Error} from '../form/commons/error'
import {FormButton} from '../form-button'
import {secondsTillFormSubmitIsAllowed} from '../../selectors/form-submit-errors'
import {FormSubmitButtonProps} from './interfaces'

export const FormSubmitButton = ({
  onClick,
  previewTooltipText,
  dataHook,
  text,
  disabled,
  errorTime,
  canRetrySubmit,
  error,
  t,
}: FormSubmitButtonProps) => {
  const [submitDelayed, setSubmitDelayed] = useState(false)
  const timeout = secondsTillFormSubmitIsAllowed(errorTime)
  const showError = !submitDelayed && !(timeout && canRetrySubmit)

  useEffect(() => {
    if (errorTime && canRetrySubmit) {
      setSubmitDelayed(true)
      setTimeout(() => {
        setSubmitDelayed(false)
      }, secondsTillFormSubmitIsAllowed(errorTime) * 1000)
    }
  }, [errorTime, canRetrySubmit])

  return (
    <>
      <FormButton
        text={text}
        type="submit"
        spinner={submitDelayed}
        dataHook={dataHook}
        disabled={disabled || submitDelayed}
        previewTooltipText={previewTooltipText}
        onClick={onClick}
      />
      {showError ? <Error className={s.error} error={error} /> : null}
    </>
  )
}
