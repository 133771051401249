import {SeatingPlan} from '@wix/ambassador-seating-v1-seating-plan/types'
import {getScaledViewBox, getPlanViewBoxValues, Coordinates, BoundingRectangle} from '@wix/seating-common-components'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {throttle} from 'lodash'
import React, {useState, PointerEventHandler, useEffect, PointerEvent, useCallback} from 'react'

export const MIN_ZOOM = 0.2
export const MAX_ZOOM = 2

const RESIZE_THROTTLE_DELAY = 100

const INITIAL_MOBILE_PADDING = 32
const INITIAL_DESKTOP_PADDING = 68

interface ChangeSvgViewBoxArgs {
  deltaX?: number
  deltaY?: number
  deltaScale?: number
  zoomPosition?: Coordinates
}

interface TouchInfo {
  distance: number
  mid: Coordinates
}

type TouchPositions = {[id: number]: Coordinates}

export const useViewBox = (plan: SeatingPlan = {}, containerRef?: React.MutableRefObject<Element>) => {
  const container = containerRef?.current

  const {isMobile: mobile} = useEnvironment()

  const [viewBox, setViewBox] = useState<BoundingRectangle>(null)
  const [touchPositions, setTouchPositions] = useState<TouchPositions>({})
  const [startDistance, setStartDistance] = useState<number>(0)
  const [startScale, setStartScale] = useState<number>(1)
  const [touchInfo, setTouchInfo] = useState<TouchInfo>({distance: 0, mid: null})
  const [pan, setPan] = useState<Coordinates>(null)
  const [scale, setScale] = useState<number>(1)
  const [containerBox, setContainerBox] = useState<BoundingRectangle>(null)
  const [minZoom, setMinZoom] = useState(MIN_ZOOM)

  const padding = mobile ? INITIAL_MOBILE_PADDING : INITIAL_DESKTOP_PADDING

  useEffect(() => {
    if (containerRef?.current && !pan) {
      const containerBoundingBox = containerRef.current.getBoundingClientRect()
      const box = getPlanViewBoxValues({plan, container: containerRef.current, padding})
      const calculatedScale = containerBoundingBox.width / box.width

      const {centeringX, centeringY} = getScaledViewBox({
        scale: calculatedScale,
        container: containerRef.current,
      })
      setPan({x: box.x + centeringX, y: box.y + centeringY})
      setScale(calculatedScale)
      setMinZoom(Math.min(MIN_ZOOM, calculatedScale))
    }
  }, [containerRef, plan, mobile, padding])

  const getTouchPosition = useCallback(
    (event: PointerEvent): Coordinates => {
      const containerBoundingBox = container.getBoundingClientRect()
      const position: Coordinates = {x: event.clientX, y: event.clientY}

      position.x -= containerBoundingBox.left
      position.y -= containerBoundingBox.top

      return position
    },
    [container],
  )

  const isPointerDown = useCallback(() => {
    const coordinates = Object.values(touchPositions)
    return Boolean(coordinates.length)
  }, [touchPositions])

  const getTouchInfo = useCallback(
    (newTouchPositions?: TouchPositions) => {
      const positions = Object.values(newTouchPositions ?? touchPositions)

      if (positions.length <= 1) {
        return {distance: 0, mid: positions[0] ?? null}
      }

      const xCoordinates = positions.map(({x: xCoordinate}) => xCoordinate)
      const xSum = xCoordinates.reduce((sum, value) => sum + value)
      const midX = xSum / xCoordinates.length

      const yCoordinates = positions.map(({y: yCoordinate}) => yCoordinate)
      const ySum = yCoordinates.reduce((sum, value) => sum + value)
      const midY = ySum / yCoordinates.length

      const distances = positions.map(({x: xCoordinate, y: yCoordinate}) =>
        Math.hypot(Math.abs(midX - xCoordinate), Math.abs(midY - yCoordinate)),
      )

      const distancesSum = distances.reduce((sum, value) => sum + value)
      const averageDistance = distancesSum / distances.length

      return {distance: averageDistance, mid: {x: midX, y: midY}}
    },
    [touchPositions],
  )

  const changeSvgViewBox = useCallback(
    ({deltaX = 0, deltaY = 0, deltaScale = 0, zoomPosition = null}: ChangeSvgViewBoxArgs) => {
      const {x: notCenteredX, y: notCenteredY} = pan

      let minX = notCenteredX - deltaX
      let minY = notCenteredY - deltaY

      const newScale = Math.min(MAX_ZOOM, Math.max(minZoom, scale + deltaScale))

      const {
        centeringX,
        centeringY,
        width: centeringWidth,
        height: centeringHeight,
        deltaX: xCorrection,
        deltaY: yCorrection,
      } = getScaledViewBox({
        scale: newScale,
        container: containerRef.current,
        customCentering:
          zoomPosition && deltaScale
            ? {
                oldScale: scale,
                zoomPosition,
              }
            : null,
      })

      minX += xCorrection
      minY += yCorrection

      setPan({x: minX, y: minY})
      setScale(newScale)

      minX -= centeringX
      minY -= centeringY

      setViewBox({x: minX, y: minY, width: centeringWidth, height: centeringHeight})
    },
    [containerRef, pan, scale, minZoom],
  )

  useEffect(() => {
    if (!containerBox && container) {
      setContainerBox(containerRef.current.getBoundingClientRect())
    }

    const handleResize = throttle(() => {
      const newBox = containerRef.current.getBoundingClientRect()
      const deltaX = (newBox.width - containerBox.width) / scale
      const deltaY = (newBox.height - containerBox.height) / scale
      setContainerBox(newBox)
      changeSvgViewBox({deltaX, deltaY})
    }, RESIZE_THROTTLE_DELAY)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [changeSvgViewBox, container, containerBox, containerRef, scale])

  const onPointerDown: PointerEventHandler<HTMLDivElement> = useCallback(
    (event: PointerEvent) => {
      event.preventDefault()

      const newTouchPositions: TouchPositions = {
        ...touchPositions,
        [event.pointerId]: getTouchPosition(event),
      }

      setTouchPositions(newTouchPositions)

      const newTouchInfo = getTouchInfo(newTouchPositions)
      setTouchInfo(newTouchInfo)

      setStartDistance(newTouchInfo.distance)
      setStartScale(scale)
    },
    [touchPositions, getTouchPosition, getTouchInfo, scale],
  )

  const onPointerMove: PointerEventHandler<HTMLDivElement> = useCallback(
    (event: PointerEvent) => {
      event.preventDefault()

      if (!isPointerDown()) {
        return
      }

      const newTouchPositions: TouchPositions = {
        ...touchPositions,
        [event.pointerId]: getTouchPosition(event),
      }

      setTouchPositions(newTouchPositions)

      const pointerIds = Object.keys(touchPositions)

      if (event.pointerId !== Number(pointerIds[pointerIds.length - 1])) {
        return
      }

      const newTouchInfo = getTouchInfo(newTouchPositions)

      const deltaX = (newTouchInfo.mid.x - touchInfo.mid.x) / scale
      const deltaY = (newTouchInfo.mid.y - touchInfo.mid.y) / scale

      const dsFromStart =
        startDistance && startScale
          ? ((newTouchInfo.distance - startDistance) * (MAX_ZOOM - minZoom)) /
            ((container.clientHeight + container.clientWidth) / 2)
          : 0

      const deltaScale = startScale + dsFromStart - scale

      changeSvgViewBox({deltaX, deltaY, deltaScale, zoomPosition: newTouchInfo.mid})
      setTouchInfo(newTouchInfo)
    },
    [
      changeSvgViewBox,
      container,
      getTouchInfo,
      getTouchPosition,
      isPointerDown,
      scale,
      startDistance,
      startScale,
      touchInfo,
      touchPositions,
      minZoom,
    ],
  )

  const onPointerUp = useCallback(() => {
    setTouchPositions({})
    setStartDistance(null)
    setStartScale(null)
  }, [])

  useEffect(() => {
    if (pan && !viewBox) {
      changeSvgViewBox({deltaX: 0, deltaY: 0, deltaScale: 0})
    }
  }, [changeSvgViewBox, pan, viewBox])

  return {
    ready: Boolean(viewBox),
    viewBox: viewBox ? `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` : null,
    zoom: scale,
    cursor: isPointerDown() ? 'grabbing' : 'grab',
    changeSvgViewBox,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onPointerLeave: onPointerUp,
    minZoom,
  }
}
