import React from 'react'
import {Tags as TagsTPA} from 'wix-ui-tpa/Tags'
import {ALIGNMENT, SIZE, SKIN} from 'wix-ui-tpa/dist/src/components/Tags/constants'
import classNames from 'classnames'
import {classes} from './tags.st.css'
import {TagsProps} from './index'

export const Tags: React.FC<TagsProps> = ({className, items}) => (
  <TagsTPA
    className={classNames(classes.tags, className)}
    alignment={ALIGNMENT.left}
    skin={SKIN.light}
    size={SIZE.small}
    onClick={() => null}
    items={items}
  />
)
